    <template>
  <v-container>
    <!-- 

        todo : status 

     -->
    <v-data-table
      :headers="headers"
      :items="services"
      class="elevation-1"
      item-key="id"
    >
      <template v-slot:item.images="{ item }">
        <v-btn color="success" text @click="openImagesDialog(item)"
          >Open Images</v-btn
        >
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ formatedTS(item.created_at) }}
      </template>
      <template v-slot:item.custom="{ item }">
        {{
          item.custom == null || item.custom == "" || item.custom == 0
            ? "Default"
            : item.custom
        }}
      </template>

      <template v-slot:item.created_by="{ item }">
        <v-btn color="success" @click="openCreatedByDialog(item.created_by)"
          >Show</v-btn
        >
      </template>
      <template v-slot:item.category_ref="{ item }">
        <v-btn color="success" @click="openCategoryDialog(item.category_ref)"
          >Show</v-btn
        >
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn color="success" @click="setEdit(item)"> Edit </v-btn>
      </template>
    </v-data-table>
    <br />
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Service</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editedItem.name"
                  label="Service Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editedItem.desc"
                  label="Description"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editedItem.custom"
                  label="Set Custom Percentage"
                  hide-details
                  type="number"
                ></v-text-field>
                <small>To apply default , just keep it empty</small>
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  label="Featured"
                  v-model="editedItem.featured"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              editDialog = false;
              editedItem = {};
            "
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" :loading="loading" text @click="save"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="imagesDialog"
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-toolbar color="primary">
        <v-spacer></v-spacer>
        <v-btn
          fab
          text
          @click="
            laodedImages = [];
            imagesDialog = false;
          "
        >
          <v-icon>mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-carousel>
            <v-carousel-item v-for="(v, k) in loadedImages" :key="k">
              <v-img :src="v" />
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="createdByDialog"
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-toolbar color="primary">
        <span>Service Owner Information</span>
        <v-spacer></v-spacer>
        <v-btn fab text @click="createdByDialog = false">
          <v-icon>mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <v-card-text v-if="loadedCreatedBy">
          Name :
          <h4>{{ loadedCreatedBy.name }}</h4>
          Email :
          <h5>{{ loadedCreatedBy.email }}</h5>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="categoryDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-toolbar color="primary">
        <span>Category Information</span>
        <v-spacer></v-spacer>
        <v-btn fab text @click="categoryDialog = false">
          <v-icon>mdi-close </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <v-card-text v-if="loadedCategory">
          <h4>Title : {{ loadedCategory.title }}</h4>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import firebase from "firebase";
export default {
  data() {
    return {
      loadedImages: [],
      imagesDialog: false,
      loading: false,
      editedItem: {},
      editDialog: false,
      createdByDialog: false,
      loadedCreatedBy: null,

      categoryDialog: false,
      loadedCategory: null,
      dialog: false,
      services: [],
      headers: [
        {
          text: "Sr .",
          align: "start",
          sortable: true,
          value: "counter",
        },
        { text: "Service Title", value: "name", sortable: true },
        {
          text: "Service Description",
          value: "desc",
          sortable: true,
        },
        {
          text: "Images",
          value: "images",
          sortable: false,
        },

        {
          text: "Cost",
          value: "cost",
          sortable: true,
        },
        {
          text: "Country",
          value: "country",
          sortable: true,
        },
        {
          text: "Currency",
          value: "currency",
          sortable: true,
        },
        { text: "Added On", value: "created_at", sortable: true },
        { text: "Orders", value: "orders", sortable: true },
        { text: "Service Area", value: "service_area", sortable: true },
        { text: "Parent Category", value: "category_ref", sortable: true },
        { text: "Create by", value: "created_by", sortable: true },
        { text: "Custom Percentage", value: "custom", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    async openCreatedByDialog(c) {
      var d = await c.get();
      this.createdByDialog = true;
      this.loadedCreatedBy = d.data();
    },

    async openCategoryDialog(c) {
      var d = await c.get();
      this.categoryDialog = true;
      this.loadedCategory = d.data();
    },

    setEdit(item) {
      this.editedItem = item;
      this.editDialog = true;
    },
    formatedTS(t) {
      var d = new Date();
      var m = d.getMonth() + 1;
      var y = d.getFullYear();
      var d = d.getDate();

      return ` ${y}-${m}-${d}`;
    },
    openImagesDialog(v) {
      this.loadedImages = v.images;
      this.imagesDialog = true;
    },
    reset() {
      this.editedItem = {};
      this.editDialog = false;
      this.loading = false;
    },

    save() {
      this.loading = true;

      this.$store
        .dispatch("editService", {
          id: this.editedItem.id,
          name: this.editedItem.name,
          desc: this.editedItem.desc,
          custom: this.editedItem.custom,
          featured: this.editedItem.featured,
          
        })
        .then((res) => {
          this.reset();
        })
        .catch((err) => {
          alert("an error occured " + err);
          this.loading = false;
        });
    },
    deleteItem(item) {
      if (confirm("Are you sure you want to delete ?")) {
        this.$store
          .dispatch("deleteService", {
            id: item.id,
          })
          .then((res) => {
            this.reset();
          })
          .catch((err) => {
            alert("an error occured " + err);
          });
      }
    },
    loadCategories() {
      firebase
        .firestore()
        .collection("categories")
        .get()
        .then((res) => {
          var arr = [];
          res.forEach((r) => {
            arr.push(r.data());
          });
          this.categories = arr;
        });
    },
  },

  mounted() {
    this.$store.dispatch("subscribeToServices", (services) => {
      this.services = services;
    });
  },
};
</script>
<style>
</style>