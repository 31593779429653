var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.services,"item-key":"id"},scopedSlots:_vm._u([{key:"item.images",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.openImagesDialog(item)}}},[_vm._v("Open Images")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatedTS(item.created_at))+" ")]}},{key:"item.custom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.custom == null || item.custom == "" || item.custom == 0 ? "Default" : item.custom)+" ")]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.openCreatedByDialog(item.created_by)}}},[_vm._v("Show")])]}},{key:"item.category_ref",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.openCategoryDialog(item.category_ref)}}},[_vm._v("Show")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.setEdit(item)}}},[_vm._v(" Edit ")])]}}])}),_c('br'),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Edit Service")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Service Name"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Description"},model:{value:(_vm.editedItem.desc),callback:function ($$v) {_vm.$set(_vm.editedItem, "desc", $$v)},expression:"editedItem.desc"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Set Custom Percentage","hide-details":"","type":"number"},model:{value:(_vm.editedItem.custom),callback:function ($$v) {_vm.$set(_vm.editedItem, "custom", $$v)},expression:"editedItem.custom"}}),_c('small',[_vm._v("To apply default , just keep it empty")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":"Featured"},model:{value:(_vm.editedItem.featured),callback:function ($$v) {_vm.$set(_vm.editedItem, "featured", $$v)},expression:"editedItem.featured"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.editDialog = false;
            _vm.editedItem = {};}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","loading":_vm.loading,"text":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1),_c('v-dialog',{attrs:{"overlay":false,"max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.imagesDialog),callback:function ($$v) {_vm.imagesDialog=$$v},expression:"imagesDialog"}},[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","text":""},on:{"click":function($event){_vm.laodedImages = [];
          _vm.imagesDialog = false;}}},[_c('v-icon',[_vm._v("mdi-close ")])],1)],1),_c('v-card',[_c('v-card-text',[_c('v-carousel',_vm._l((_vm.loadedImages),function(v,k){return _c('v-carousel-item',{key:k},[_c('v-img',{attrs:{"src":v}})],1)}),1)],1)],1)],1),_c('v-dialog',{attrs:{"overlay":false,"max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.createdByDialog),callback:function ($$v) {_vm.createdByDialog=$$v},expression:"createdByDialog"}},[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('span',[_vm._v("Service Owner Information")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","text":""},on:{"click":function($event){_vm.createdByDialog = false}}},[_c('v-icon',[_vm._v("mdi-close ")])],1)],1),_c('v-card',[(_vm.loadedCreatedBy)?_c('v-card-text',[_vm._v(" Name : "),_c('h4',[_vm._v(_vm._s(_vm.loadedCreatedBy.name))]),_vm._v(" Email : "),_c('h5',[_vm._v(_vm._s(_vm.loadedCreatedBy.email))])]):_vm._e()],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.categoryDialog),callback:function ($$v) {_vm.categoryDialog=$$v},expression:"categoryDialog"}},[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('span',[_vm._v("Category Information")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","text":""},on:{"click":function($event){_vm.categoryDialog = false}}},[_c('v-icon',[_vm._v("mdi-close ")])],1)],1),_c('v-card',[(_vm.loadedCategory)?_c('v-card-text',[_c('h4',[_vm._v("Title : "+_vm._s(_vm.loadedCategory.title))])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }